import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.scss']
})


export class SolutionsComponent implements OnInit {
  solutionDetails:any = {
    development: "Transform your ideas into reality with our bespoke software development services. Our talented team of developers excels in creating tailor-made solutions that align perfectly with your business objectives.",
    visualDesign: "Elevate your brand and user experience with our visual design expertise. From intuitive user interfaces to compelling graphics, we create visually stunning designs that enhance your digital presence and captivate your audience.",
    qaTesting: "Ensure the reliability and performance of your software applications with our comprehensive QA testing services. Our testing experts rigorously assess your systems, identify potential issues, and implement robust testing strategies to guarantee a seamless user experience.",
    itManagement: "Focus on your core business activities while we take care of your IT infrastructure and support. Our managed IT services ensure proactive monitoring, timely updates, and swift issue resolution, ensuring your systems run seamlessly.",
    cyberSecurity: "Safeguard your digital assets with our state-of-the-art cybersecurity solutions. We employ the latest technologies and strategies to protect your organization from evolving cyber threats and ensure compliance with industry regulations.",
    blockChain: "Explore the potential of blockchain technology with our development services. Whether you're looking to implement smart contracts, decentralized applications (DApps), or enhance the security of your transactions, we bring blockchain solutions to the forefront of your business strategy.",
    aiMl: "Embrace the power of artificial intelligence and machine learning. Our solutions leverage advanced algorithms and data-driven insights to enhance automation, optimize processes, and deliver intelligent solutions tailored to your specific industry.",
    dataAnalytics: "Unlock valuable insights hidden within your data. Our data analytics and business intelligence solutions empower you to make informed decisions, drive innovation, and gain a competitive edge in your industry.",
    iot: "Connect, analyze, and derive insights from the Internet of Things (IoT). Our IoT solutions enable you to harness the power of connected devices, streamline operations, and unlock new possibilities for innovation and efficiency.",
  }

  constructor() { }

  ngOnInit(): void {

  }

}
