import { Component } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
  })
  export class TruncatePipe implements PipeTransform {
    transform(value: string, maxLength: number): string {
      if (!value) {
        return '';
      }
  
      if (value.length <= maxLength) {
        return value;
      }
  
      return value.substring(0, maxLength) + '...';
    }
  }
  

declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})



export class AppComponent {
    location: any;
    routerSubscription: any;

    constructor(
        private router: Router
    ) {}

    ngOnInit(){
        this.recallJsFuntions();
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
}