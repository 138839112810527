import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-home-one',
    templateUrl: './home-one.component.html',
    styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {
    solutionDetails:any = {
        development: "Transform your ideas into reality with our bespoke software development services. Our talented team of developers excels in creating tailor-made solutions that align perfectly with your business objectives.",
        visualDesign: "Elevate your brand and user experience with our visual design expertise. From intuitive user interfaces to compelling graphics, we create visually stunning designs that enhance your digital presence and captivate your audience.",
        qaTesting: "Ensure the reliability and performance of your software applications with our comprehensive QA testing services. Our testing experts rigorously assess your systems, identify potential issues, and implement robust testing strategies to guarantee a seamless user experience.",
        itManagement: "Focus on your core business activities while we take care of your IT infrastructure and support. Our managed IT services ensure proactive monitoring, timely updates, and swift issue resolution, ensuring your systems run seamlessly.",
        cyberSecurity: "Safeguard your digital assets with our state-of-the-art cybersecurity solutions. We employ the latest technologies and strategies to protect your organization from evolving cyber threats and ensure compliance with industry regulations.",
        blockChain: "Explore the potential of blockchain technology with our development services. Whether you're looking to implement smart contracts, decentralized applications (DApps), or enhance the security of your transactions, we bring blockchain solutions to the forefront of your business strategy.",
        aiMl: "Embrace the power of artificial intelligence and machine learning. Our solutions leverage advanced algorithms and data-driven insights to enhance automation, optimize processes, and deliver intelligent solutions tailored to your specific industry.",
        dataAnalytics: "Unlock valuable insights hidden within your data. Our data analytics and business intelligence solutions empower you to make informed decisions, drive innovation, and gain a competitive edge in your industry.",
        iot: "Connect, analyze, and derive insights from the Internet of Things (IoT). Our IoT solutions enable you to harness the power of connected devices, streamline operations, and unlock new possibilities for innovation and efficiency.",
      }

    constructor() { }

    ngOnInit(): void {}

    teamSlides: OwlOptions = {
		loop: true,
        margin: 20,
        dots: true,
        autoplay: true,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
            },
            575: {
                items: 2,
            },
            576: {
                items: 2,
            },
            768: {
                items: 3,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
            }
        }
    }
    clientWrap: OwlOptions = {
		loop:true,
		margin:30,
		nav:false,
		mouseDrag: true,
		items:1,
		dots: false,
		autoHeight: true,
		autoplay: true,
		smartSpeed: 800,
		autoplayHoverPause: true,
		center: false,
		responsive:{
			0:{
				items:1,
				margin: 10,
			},
			576:{
				items:1,
			},
			768:{
				items:2,
				margin: 20,
			},
			992:{
				items:2,
			},
			1200:{
				items:2,
			}
		}
    }

    // Accordion
    accordionItems = [
        {
            title: 'Great Understanding',
            content: `Explore our profound insights into your business needs, ensuring tailored solutions that align seamlessly with your objectives and foster innovation.`,
            open: false
        },
        {
            title: 'Update Technology',
            content: `Stay ahead with our commitment to cutting-edge technologies, delivering modern solutions that empower your business to thrive in a rapidly evolving digital landscape.`,
            open: false
        },
        {
            title: 'Experienced Team',
            content: `Rely on our seasoned professionals, a dedicated team with a wealth of expertise, to guide your projects from conception to fruition with unparalleled skill and experience.`,
            open: false
        },
        {
            title: 'Best Quality Service',
            content: `Elevate your expectations with our commitment to excellence, offering top-notch services that prioritize quality, reliability, and client satisfaction at every step.`,
            open: false
        }
    ];
    selectedItem : any = null;
    toggleAccordionItem(item:any) {
        item.open = !item.open;
        if (this.selectedItem && this.selectedItem !== item) {
            this.selectedItem.open = false;
        }
        this.selectedItem = item;
    }
    
    // Tabs
    currentTab = 'tab1';
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;
    }

}