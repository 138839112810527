import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  redirectToEmail() {
    // Replace 'your-email@example.com' with the desired email address
    const email = 'info@byte2tech.com';
    
    // Redirect to the default email client with a subject
    const subject = '';
    const mailtoLink = `mailto:${email}?subject=${subject}`;

    // Open the default email client
    window.location.href = mailtoLink;
  }

}
