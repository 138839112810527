<section class="banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1>Your Trusted Partner in IT Consultancy and Services</h1>
                            <p>Solving Today's Challenges with Tomorrow's Technology</p>
                            <div class="banner-btn">
                                <a routerLink="/contact" class="box-btn">Contact Us</a>
                                <a routerLink="/about" class="box-btn border-btn">Know More</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="banner-img">
                            <img src="assets/images/home-bg-1-img.png" alt="banner-img" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="home-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape"></div>
        <div class="shape2"><img src="assets/images/shape/2.png" alt="shape"></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape"></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape"></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape"></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape"></div>
    </div>
</section>

<section class="home-service-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Smart Services</span>
            <h2>Byte2Tech Solutions Provide All Kind of Tech Solutions</h2>
            <p>At Byte2Tech Solutions, we understand that every business is unique, and that's why we offer a diverse range of cutting-edge IT solutions tailored to meet your specific needs. Our Solutions Tab is your gateway to unlocking the full potential of technology for your organization.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Development</h3>
                        <p>{{solutionDetails?.development | truncate: 220}}</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s1.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Visual Design</h3>
                        <p>{{solutionDetails?.visualDesign | truncate: 220 }}</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s3.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>QA Testing</h3>
                        <p>{{solutionDetails?.qaTesting | truncate: 220 }}</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s4.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>IT Management</h3>
                        <p>{{solutionDetails?.itManagement | truncate: 220 }} </p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Cyber Security</h3>
                        <p>{{solutionDetails?.cyberSecurity | truncate: 220}}</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s7.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Blockchain Development</h3>
                        <p>{{solutionDetails?.blockChain | truncate: 220}}</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s8.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>AI/ML Development</h3>
                        <p>{{solutionDetails?.aiMl | truncate: 220}}</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s6.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>IoT Solutions</h3>
                        <p>{{solutionDetails?.iot | truncate: 220}}</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s6.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3> Business Intelligence</h3>
                        <p>{{solutionDetails?.dataAnalytics | truncate: 220 }}</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-12">
                <div class="page-navigation-area">
                    <nav aria-label="Page navigation example text-center">
                        <ul class="pagination">
                            <li class="page-item"><a class="page-link page-links" routerLink="/solutions"><i class='bx bx-chevrons-left'></i></a></li>
                            <li class="page-item active"><a class="page-link" routerLink="/solutions">1</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/solutions">2</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/solutions">3</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/solutions"><i class='bx bx-chevrons-right'></i></a></li>
                        </ul>
                    </nav>
                </div>
            </div> -->
        </div>
    </div>
</section>

<section class="home-company-area bg-color">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <h2>Innovative It Helping Service Allover the World</h2>
                        <span>Our Company</span>
                        <p>At Byte2Tech Solutions, we are more than just an IT consultancy – we are your strategic
                            partner in navigating the dynamic landscape of technology. Established with a vision to
                            empower businesses, we bring a wealth of expertise to the table, offering tailored solutions
                            that drive innovation and ensure sustainable growth.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/company/company-img.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="choose-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Choose Byte2Tech Solutions</span>
            <h2>We Achieved People’s Trust by Our Great Service</h2>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p> -->
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img.png" alt="choose" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <div class="faq-item" *ngFor="let item of accordionItems; let i = index;">
                            <div class="faq-header" (click)="toggleAccordionItem(item)">
                                {{item.title}}
                                <i class='bx' [ngClass]="{'bx-plus': !item.open, 'bx-x': item.open}"></i>
                            </div>
                            <div class="faq-content" [hidden]="!item.open">
                                <p>{{item.content}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="home-process-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Working Process</span>
            <h2>We are popular because of our way of working</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p1.png" alt="process" />
                        <span><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Research Product</h3>
                        <p>Lorem ipsum dolor sit amet, co nsectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p2.png" alt="process" />
                        <span class="pro-span"><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>User Testing</h3>
                        <p>Lorem ipsum dolor sit amet, co nsectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p3.png" alt="process" />
                        <span><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Development</h3>
                        <p>Lorem ipsum dolor sit amet, co nsectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p4.png" alt="process" />
                    </div>

                    <div class="content">
                        <h3>Product Handover</h3>
                        <p>Lorem ipsum dolor sit amet, co nsectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-case ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Case Studies</span>
            <h2>Have a Look Our Work Showcase</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
        </div>
        <div class="case">
            <ul class="nav-tabset">
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                    <span (click)="switchTab($event, 'tab1')">
                        All
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                    <span (click)="switchTab($event, 'tab2')">
                        Design
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                    <span (click)="switchTab($event, 'tab3')">
                        Development
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab4'}">
                    <span (click)="switchTab($event, 'tab4')">
                        Cyber Security
                    </span>
                </li>
            </ul>
            <div class="tabs-container">
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c1.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Joe’s Company Software Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c2.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Ride Share App UX Studies & Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c3.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Restaurant Management & Web Developing</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c4.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>IT Software Company Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c5.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Parking Management & Web Developing</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c6.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Temperature App UX Studies & Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c4.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>IT Software Company Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c5.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Parking Management & Web Developing</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c6.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Temperature App UX Studies & Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c2.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Ride Share App UX Studies & Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c3.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Restaurant Management & Web Developing</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c4.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>IT Software Company Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab4" *ngIf="currentTab === 'tab4'">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c6.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Temperature App UX Studies & Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c4.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>IT Software Company Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c5.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Parking Management & Web Developing</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="case-btn text-center">
            <p>We Have More Amazing Cases. <a routerLink="/">View More</a></p>
        </div>
    </div>
</section> -->

<section class="feature-area bg-color ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <span>Features</span>
                        <h2>We Have Also Some Features That Provided by Byte2Tech Solutions</h2>
                        <p>Whether you're a startup navigating the tech landscape or an established enterprise seeking
                            digital transformation, Byte2Tech Solutions is here to guide you every step of the way.
                            Let's redefine the future of your business together.</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i> IT Consultancy</li>
                        <li><i class="flaticon-correct"></i> Software Development</li>
                        <li><i class="flaticon-correct"></i> Technological Consultation</li>
                        <li><i class="flaticon-correct"></i> QA & Testing</li>
                        <li><i class="flaticon-correct"></i> Cloud Solutions</li>
                        <li><i class="flaticon-correct"></i> IT Management</li>
                    </ul>
                    <a routerLink="/contact" class="box-btn">Lets Talk!</a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="home-team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Team Members</span>
            <h2>People Who are Behind the Achievements</h2>
            <p>Meet our dynamic team of passionate professionals, whose collective expertise, creativity, and unwavering commitment drive the success stories we create for our clients</p>
        </div>
        <div class="home-team-slider">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t1.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>John Smith</h3>
                            <p>Full Stack Developer</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t2.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>Evana Doe</h3>
                            <p>Web Developer</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t3.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>Bread Mc</h3>
                            <p>IT Consulting</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t4.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>Maria Fread</h3>
                            <p>UI/UX Designer</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t1.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>John Smith</h3>
                            <p>Full Stack Developer</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t2.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>Evana Doe</h3>
                            <p>Web Developer</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t3.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>Bread Mc</h3>
                            <p>IT Consulting</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t4.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>Maria Fread</h3>
                            <p>UI/UX Designer</p>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section> -->

<!-- <section class="client-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span>Testimonials</span>
            <h2>What Our Client’s Say</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque blanditiis exercitationem incidunt, alias corporis quam assumenda dicta.</p>
        </div>
        <div class="client-wrap">
            <owl-carousel-o [options]="clientWrap">
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/1.jpg" alt="img">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing Quis suspendisse typesetting ipsum dolor sit amet,</p>
                        <h3>Steven Jony</h3>
                        <span>CEO of Company</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/2.jpg" alt="img">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing Quis suspendisse typesetting ipsum dolor sit amet,</p>
                        <h3>Omit Jacson</h3>
                        <span>Company Founder</span>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>

<section class="home-blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Blog Post</span>
            <h2>Our Regular Blogs</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque blanditiis exercitationem incidunt, alias corporis quam assumenda dicta.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/images/blog/1.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>10 April 2024</li>
                            <li><a routerLink="/blog">By Admin</a></li>
                        </ul>
                        <a routerLink="/blog-details"><h3>Joe’s Company Software Development Case</h3></a>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas in fugit minima modi perspiciatis nam aspernatur porro</p>
                        <a routerLink="/blog-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/images/blog/5.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>10 April 2024</li>
                            <li><a routerLink="/blog">By Admin</a></li>
                        </ul>
                        <a routerLink="/blog-details"><h3>Temperature App UX Studies & Development Case</h3></a>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas in fugit minima modi perspiciatis nam aspernatur porro</p>
                        <a routerLink="/blog-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/images/blog/3.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>10 April 2024</li>
                            <li><a routerLink="/blog">By Admin</a></li>
                        </ul>
                        <a routerLink="/blog-details"><h3>IT Software Company Development Case</h3></a>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas in fugit minima modi perspiciatis nam aspernatur porro</p>
                        <a routerLink="/blog-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="blog-btn text-center">
            <p>We Have More Usefull Blogs For You. <a routerLink="/blog">View More</a></p>
        </div>
    </div>
</section> -->

<section class="home-contact-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span>
            <h2>Let Us Know About Your Project Idea!</h2>
            <p>Thank you for considering Byte2Tech Solutions for your IT consultancy and services. We're eager to hear
                from you and explore how we can contribute to the success of your business. Whether you have a specific
                project in mind or simply want to learn more about our offerings, we're here to assist.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="contact-img">
                    <img src="assets/images/contact-img.png" alt="contact">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" required placeholder="Your Email" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Subject" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="5" required placeholder="Your Message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn page-btn box-btn">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>