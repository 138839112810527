<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">About</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center" style="margin-top: 5%;">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <h2>Innovative It Helping Service Allover the World</h2>
                        <span>Our Company</span>
                        <p>At Byte2Tech Solutions, we are more than just an IT consultancy – we are your strategic
                            partner in navigating the dynamic landscape of technology. Established with a vision to
                            empower businesses, we bring a wealth of expertise to the table, offering tailored solutions
                            that drive innovation and ensure sustainable growth.</p>
                    </div>
                    <div class="company-tittle">
                        <span>Our Mission</span>
                        <p>Byte2Tech Solutions is dedicated to providing top-notch IT consultancy and services, helping
                            businesses harness the full potential of technology to stay ahead in today's fast-paced
                            digital world. Our mission is to deliver exceptional value by combining technical prowess
                            with strategic insights, offering solutions that not only solve immediate challenges but
                            also pave the way for future success.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/company/company-img.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12" style="margin-bottom: 2%;">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Why Choose Byte2Tech</span>
                        <h3>Expertise</h3>
                        <p>Our team comprises seasoned professionals with diverse skills and a passion for staying at
                            the forefront of technological advancements.</p>
                        <h3>Innovation</h3>
                        <p>We thrive on innovation, crafting solutions that are not only effective but also
                            future-proof, ensuring your business stays ahead of the curve.</p>
                        <h3>Client-Centric Approach</h3>
                        <p>Your success is our priority. We take the time to understand your unique challenges and
                            goals, delivering personalized solutions.</p>
                        <h3>Integrity</h3>
                        <p>Transparency, honesty, and ethical practices are the foundation of our interactions. You can
                            trust us to be a reliable partner on your journey to digital excellence.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/about.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img1.jpg" alt="choose" />

                    <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Updated Technology</h2>
                    <p>You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with.</p>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going There are many variations of passages of Lorem Ipsum available, but the.</p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- Video Popup -->
<!-- <div class="video-popup" *ngIf="isOpen">
    <div class="popup-inner">
        <iframe src="https://www.youtube.com/embed/XNQk1qt__Vw"
            title="Setup and Local Installation - React Nextjs Online Yoga Coaching &amp; Training Courses System"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
        <button (click)="closePopup()" type="button" class="close-btn">
            <i class='bx bx-x'></i>
        </button>
    </div>
</div> -->

<section class="feature-area ptb-50">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <span>Features</span>
                        <h2>We Have Also Some Features That Provided by Byte2Tech Solutions</h2>
                        <p>Whether you're a startup navigating the tech landscape or an established enterprise seeking
                            digital transformation, Byte2Tech Solutions is here to guide you every step of the way.
                            Let's redefine the future of your business together.</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i> IT Consultancy</li>
                        <li><i class="flaticon-correct"></i> Software Development</li>
                        <li><i class="flaticon-correct"></i> Technological Consultation</li>
                        <li><i class="flaticon-correct"></i> QA & Testing</li>
                        <li><i class="flaticon-correct"></i> Cloud Solutions</li>
                        <li><i class="flaticon-correct"></i> IT Management</li>
                    </ul>
                    <a routerLink="/contact" class="box-btn">Lets Talk!</a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="client-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span>Testimonials</span>
            <h2>What Our Client’s Say</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque
                blanditiis exercitationem incidunt, alias corporis quam assumenda dicta.</p>
        </div>
        <div class="client-wrap">
            <owl-carousel-o [options]="clientWrap">
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/1.jpg" alt="img">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing Quis
                            suspendisse typesetting ipsum dolor sit amet,</p>
                        <h3>Steven Jony</h3>
                        <span>CEO of Company</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/2.jpg" alt="img">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing Quis
                            suspendisse typesetting ipsum dolor sit amet,</p>
                        <h3>Omit Jacson</h3>
                        <span>Company Founder</span>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section> -->