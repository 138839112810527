<footer class="footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/images/logo-250x100.svg" alt="logo"/>
                        </a>
                    </div>
                    <p>Solving Today's Challenges with Tomorrow's Technology</p>

                    <div class="subscribe">
                        <h4>Join Newsletter</h4>
                        <form class="newsletter-form">
                            <input type="email" id="emails" class="form-control" placeholder="Your Email" name="EMAIL" required autocomplete="off">
                            <button class="box-btn" type="submit">Subscribe</button>
                        </form>
                    </div>

                    <ul class="social">
                        <!-- <li><a href="#" target="_blank"><i class='bx bxl-twitter' ></i></a></li> -->
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram' ></i></a></li>
                        <li><a href="https://www.linkedin.com/company/byte2tech-solutions-pvt-ltd" target="_blank"><i class='bx bxl-linkedin' ></i></a></li>
                        <li><a href="#" (click)="redirectToEmail()" target="_blank"><i class='bx bxs-envelope' ></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content ml-15">
                    <h3>Our Service</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/">IT Consultancy</a></li>
                        <li><a routerLink="/">Software Development</a></li>
                        <li><a routerLink="/">Technological Consultancy</a></li>
                        <li><a routerLink="/">QA & Testing</a></li>
                        <li><a routerLink="/">Cloud Solutions</a></li>
                        <li><a routerLink="/">IT Management</a></li>
                    </ul>
                </div>
            </div>

            <!-- <div class="col-lg-2 col-md-6">
                <div class="content">
                    <h3>Quick Links</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/faq">FAQ</a></li>
                        <li><a routerLink="/solutions">Service</a></li>
                        <li><a routerLink="/">Career</a></li>
                        <li><a routerLink="/privecy">Privacy & Policy</a></li>
                        <li><a routerLink="/terms-condition">Terms & Conditions</a></li>
                        <li><a routerLink="/">Data Analysis</a></li>
                    </ul>
                </div>
            </div> -->

            <div class="col-lg-3 col-md-6">
                <div class="content contacts">
                    <h3 class="ml-40">Contact</h3>

                    <ul class="footer-list foot-social">
                        <li><a href="tel:+918122288686"><i class="bx bx-mobile-alt"></i> +91 81222 88686</a></li>
                        <li><a href="tel:+916383263383"><i class="bx bx-phone"></i> +91 63832 63383</a></li>
                        <li><a href="mailto:info&#64;byte2tech.com"><i class="bx bxs-envelope"></i> info&#64;byte2tech.com</a></li>
                        <!-- <li><a href="mailto:support&#64;aiva.com"><i class="bx bxs-envelope"></i> support&#64;aiva.com</a></li> -->
                        <li><i class="bx bxs-map"></i> Coimbatore, TN, India</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copy-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <ul class="menu">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about">About</a></li>
                        <li><a routerLink="/solutions">Solutions</a></li>
                        <!-- <li><a routerLink="/case">Case Studies</a></li>
                        <li><a routerLink="/blog">Blog</a></li> -->
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>

                <div class="col-lg-6">
                    <p>© Byte2Tech Solutions</p>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
    <i class='bx bx-chevrons-up'></i>
</div>